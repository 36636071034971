import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import preval from "preval.macro";
import moment from "moment";
import "moment/locale/fr";

moment.locale("fr");

const Page = {
  Albums: 0,
  AliceAphorisms: 1,
  MadeleineAphorisms: 2,
};

class App extends React.Component {
  constructor(props) {
    super();
    this.state = {
      page: Page.Albums,
    };
  }

  renderContent() {
    if (this.state.page === Page.Albums) {
      return <Albums></Albums>;
    } else if (this.state.page === Page.AliceAphorisms) {
      return <AliceAphorismList></AliceAphorismList>;
    } else if (this.state.page === Page.MadeleineAphorisms) {
      return <MadeleineAphorismList></MadeleineAphorismList>;
    }
  }

  render() {
    const lastUpdate = preval`module.exports = new Date()`;
    return (
      <>
        <div className="choices">
          <div
            onClick={() => this.setState({ page: Page.Albums })}
            className={
              this.state.page === Page.Albums
                ? "albums-choice selected-choice"
                : "albums-choice unselected-choice"
            }
          >
            Albums photos
          </div>
          <div
            onClick={() => this.setState({ page: Page.AliceAphorisms })}
            className={
              this.state.page === Page.AliceAphorisms
                ? "aphorism-choice selected-choice"
                : "aphorism-choice unselected-choice"
            }
          >
            Les bons mots d'Alice
          </div>
          <div
            onClick={() => this.setState({ page: Page.MadeleineAphorisms })}
            className={
              this.state.page === Page.MadeleineAphorisms
                ? "aphorism-choice selected-choice"
                : "aphorism-choice unselected-choice"
            }
          >
            Les bons mots de Madeleine
          </div>
        </div>
        {this.renderContent()}
        <p>Dernière mise à jour: {moment(lastUpdate).format("LLLL")}.</p>
      </>
    );
  }
}

class AliceAphorismList extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: null,
      isLoaded: false,
      aphorismList: [],
    };
  }

  componentDidMount() {
    fetch("/alice_aphorism.json", {
      headers: {
        Pragma: "no-cache",
        "Cache-Control": "no-cache",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            aphorismList: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  renderAphorism(a) {
    return <Aphorism date={a.date} content={a.sentence}></Aphorism>;
  }

  render() {
    return (
      <div>
        <div className="aphorism-header">
          <h1>Les bons mots d'Alice</h1>
          <img src="/alice.jpg" alt="Alice"></img>
        </div>
        {this.state.aphorismList.map((a) => this.renderAphorism(a))}
      </div>
    );
  }
}

class MadeleineAphorismList extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: null,
      isLoaded: false,
      aphorismList: [],
    };
  }

  componentDidMount() {
    fetch("/madeleine_aphorism.json", {
      headers: {
        Pragma: "no-cache",
        "Cache-Control": "no-cache",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            aphorismList: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  renderAphorism(a) {
    return <Aphorism date={a.date} content={a.sentence}></Aphorism>;
  }

  render() {
    return (
      <div>
        <div className="aphorism-header">
          <h1>Les bons mots de Madeleine</h1>
          <img src="/madeleine.jpg" alt="Madeleine"></img>
        </div>
        {this.state.aphorismList.map((a) => this.renderAphorism(a))}
      </div>
    );
  }
}

class Aphorism extends React.Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <div className="aphorism">
        <div className="aphorism-date">
          <b>{this.props.date}</b>
        </div>
        <div
          className="aphorism-content"
          dangerouslySetInnerHTML={{ __html: this.props.content }}
        ></div>
      </div>
    );
  }
}

class Albums extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: null,
      isLoaded: false,
      albums: [],
    };
  }

  componentDidMount() {
    fetch("/albums.json", {
      headers: {
        Pragma: "no-cache",
        "Cache-Control": "no-cache",
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            albums: result,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  reducer = (acc, alb) => {
    acc[alb.year] = [...(acc[alb.year] || []), alb];
    return acc;
  };

  renderYearAlbum(year, albums) {
    return <YearAlbums year={year} albums={albums}></YearAlbums>;
  }

  render() {
    const albumPerYear = this.state.albums.reduce(this.reducer, {});
    return (
      <div>
        <h1>Albums photo de la famille Pinchedez</h1>
        {Object.keys(albumPerYear)
          .sort()
          .reverse()
          .map((k) => this.renderYearAlbum(k, albumPerYear[k]))}
      </div>
    );
  }
}

class YearAlbums extends React.Component {
  constructor(props) {
    super();
  }

  renderAlbum(album) {
    return <Album album={album}></Album>;
  }

  render() {
    return (
      <div>
        <h2 class="year">Année {this.props.year}</h2>
        <div class="yearAlbum">
          {this.props.albums.map((a) => this.renderAlbum(a))}
        </div>
      </div>
    );
  }
}

class Album extends React.Component {
  constructor(props) {
    super();
  }

  getType(album) {
    switch (album.type) {
      case "album":
        return "📷";
      case "film":
        return "🎬";
      default:
        return "📷";
    }
  }

  render() {
    const album = this.props.album;
    return (
      <div class="album">
        {this.getType(album)}{" "}
        <a href={album.link} target="_blank" rel="noopener noreferrer">
          {album.name}
        </a>
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
